import { useQuery } from 'react-query';
import axios from 'helpers/axios/axios';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { Document, Text } from '@contentful/rich-text-types/dist/types/types';
import { useAuth0 } from '../Auth0/Auth0';

type UseGetTermsConditionsType = {
  termsConditions?: Document;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}
const prefixCaret = (termsConditions: Document | undefined) => {
  if (!termsConditions) return undefined;
  const contentArray = termsConditions.content[0].content;
  const caretCharacter = '^ ';
  const caretText: Text = {
    nodeType: 'text', value: caretCharacter, marks: [], data: {}
  };
  if (!contentArray.find((content) => content.nodeType === caretText.nodeType && content.value === caretText.value)) {
    contentArray.unshift(caretText);
  }
  return termsConditions;
};
const useGetTermsConditions = (): UseGetTermsConditionsType => {
  const { isLoading: isAuth0Loading } = useAuth0();
  const { promoCode, region, isInitialised } = useSessionContext();
  const termsConditionsUrl = `/terms-conditions?promoCode=${promoCode}&region=${region}`;
  const {
    isLoading: isTermsConditionsLoading, isError, data: response, isSuccess
  } = useQuery(
    ['termsConditions', promoCode, region],
    () => axios.get(termsConditionsUrl),
    {
      enabled:
        !isAuth0Loading &&
        isInitialised &&
        Boolean(promoCode),
      retry: 2
    }
  );
  const isLoading = !isInitialised || isTermsConditionsLoading || isAuth0Loading;
  const termsConditions = response?.data?.shortTerm?.json;
  return {
    termsConditions: prefixCaret(termsConditions), isLoading, isError, isSuccess
  };
};

export default useGetTermsConditions;
