import {
  ClientInformation,
  PaymentInformation,
  CustomOrderData,
  OrderDataPayload
} from './types';
import formatPhoneNumber from '../../utils/FormatPhoneNumber';

export const buildOrderData = (
  customOrderData: CustomOrderData,
  clientInformation?: ClientInformation,
  paymentInformation?: PaymentInformation
): OrderDataPayload => {
  // TODO: This custom order data logic should really sit in the calling flows
  // and this builder should only overwrite client / payment info and add it to the payload
  const {
    transactionType,
    sessionToken,
    sourceInitiative,
    product: {
      productInstanceId
    } = {},
    contact: {
      contactIdentityId
    } = {},
    items: [{
      productFamily,
      productId,
      productTier,
      deal: {
        id: dealId = undefined,
        priceModificationValue: discountRate = undefined,
        priceModificationType: discountType = undefined
      } = {}
    }],
    isDiscountedProduct: isDiscounted,
    region,
    totalPrice,
    optIn,
    termsAndConditions,
    promoCode
  } = customOrderData;

  const phoneFormatted = formatPhoneNumber(
    clientInformation?.phoneNumber || '', region
  ) || undefined;

  return {
    // Custom order data
    // - Flow information
    transactionType,
    // - Context information
    sessionToken,
    sourceInitiative,
    productInstanceId,
    contactIdentityId,
    // - Product information
    dealId,
    discountRate,
    discountType,
    isDiscounted,
    productFamily,
    productId,
    productTier,
    region,
    totalPrice,
    // - Client 'choice'
    optIn,
    termsAndConditions,
    // - Fixed payment data
    subscription: paymentInformation ? 'Monthly' : undefined,
    promoCode,
    // Checkout domain information
    ...clientInformation,
    ...paymentInformation,
    phone: phoneFormatted
  };
};
