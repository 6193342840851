import axios from 'helpers/axios/axios';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useGetAccessToken } from 'hooks/useGetAcessToken/useGetAccessToken';

import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { get } from 'lodash';
import qs from 'qs';
import { useQuery } from 'react-query';
import config from '../../Config';
import { REQUEST_HEADERS } from '../../constants';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { MakePurchaseType, PurchaseResponse, PurchaseRequest } from './types';

const useSubscriptionPurchase = (purchaseRequest: PurchaseRequest): MakePurchaseType => {
  const { accountId, pCustomerId, isRecaptchaSuccess } = purchaseRequest;
  const { productId, region } = useSessionContext();
  const { user, appState } = useAuth0();
  const { accessToken } = useGetAccessToken();

  const { productData } = useGetProduct();

  const businessName = get(appState, 'clientInformation.businessName');
  const contactIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  const dealId = get(productData, 'items[0].deal.id');
  const { hasPurchaseSubmitted } = useSessionContext();
  const purchasePayload = {
    identity_id: contactIdentityId,
    account_id: accountId,
    billing_account_id: accountId,
    billing_account_contact_identity_id: contactIdentityId,
    deal_id: dealId,
    product_id: productId,
    account_name: businessName,
    region,
    referral_id: pCustomerId
  };
  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  return useQuery(
    'purchase',
    () => axios.post<PurchaseResponse>(
      `${config.API_PURCHASE_ENDPOINT}`,
      qs.stringify(purchasePayload),
      options
    ),
    { enabled: !!productData && !!accessToken && !hasPurchaseSubmitted && !!isRecaptchaSuccess, retry: false }
  );
};

export default useSubscriptionPurchase;
