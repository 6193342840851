import React from 'react';
import { Separator } from '@myob/myob-widgets';
import { get } from 'lodash';
import './ProductPrices.scss';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { OrderItem, ProductData } from '../../hooks/useGetProduct/types';
import {
  NZ_PAYROLL_ONLY_PRODUCT_ID,
  REGIONS,
  SHOW_MODULE_PRICE_PRODUCT_ID
} from '../../constants';
import isNzPPLProduct from '../../utils/IsNzPPLProduct';
import {
  getListPrice,
  getDiscountPrice,
  getItemPrice,
  getProductTotalPrice,
  formatToTwoDecimalPlaces
} from '../../utils/pricingUtils';
import { getTooltip } from '../../utils/toolTipUtils';

type ProductPricesProps = {
  productData: ProductData,
  moduleProductData: ProductData,
};

export const showModulePrice = (productId: string): boolean => SHOW_MODULE_PRICE_PRODUCT_ID.includes(productId);

export const ProductPrices: React.FC<ProductPricesProps> = ({ productData, moduleProductData }) => {
  const productItems = get(productData, 'items');
  const region = get(productData, 'region');
  const { productId, isAnnualPrice } = useSessionContext();
  const isAu = region === REGIONS.AUSTRALIA;
  const hasPromotionName = !!productItems[0].deal.promotionName;
  const getProductInformation = () =>
    (
      <div className= 'summary-of-your-plan-main'>
        {productItems?.map((product, index) => (
          <div key={`container-${index}`}>
            {getProductPrice(product, index)}
            {getPromotionNameAndPriceAfterDiscount(product, index)}
            {getModuleProductPrice()}
            {!isAu && getGSTFeeLine(product, index)}
          </div>))}
        {getTotalPrice()}
      </div>
    );

  const getGSTFeeLine = (product: OrderItem, index: number) => (
    <div>
      <div style={{
        display: 'flex', justifyContent: 'space-between', fontSize: '16px', marginTop: '4px'
      }}
      >
        <div>
          <h3 className='product-gst-container' key={`displayName-${index}`}>
            GST
          </h3>
        </div>
        <div data-testid='gst-amount' className='product-gst-container' key={`totalPrice-${index}`} style={{
          textAlign: 'right', flexGrow: 0.8, fontWeight: 'bold'
        }}
        >
          ${formatToTwoDecimalPlaces(product.tax)} {product.currency}
        </div>
      </div>
    </div>
  );

  const getPromotionNameAndPriceAfterDiscount = (product: OrderItem, index: number) => (
    hasPromotionName &&
    <div className='promotion-container'>
      <div className='promotionCode-tag' key={`promotionName-${index}`}>
        {product.deal?.promotionName}
      </div>
      <div data-testid='promotionCode-price' className='promotionCode-price'>
        ${getDiscountPrice(product, isAnnualPrice, region)} {product.currency} ^
      </div>
    </div>
  );

  const getTotalPrice = () =>
    (<>
      <Separator className='feature-separator'/>
      <div className='total-container' style={{
        color: 'black', display: 'flex', fontWeight: 'bold', justifyContent: 'space-between'
      }}
      >
        <div>
          {totalLineText()}
          &nbsp;{<sub className='GST-info-container' >(inc. GST)</sub>}
        </div>
        <div data-testid='total-price'>
          ${getProductTotalPrice(productItems, isAnnualPrice)} {productData && productData.currency}
        </div>
      </div>
    </>
    );

  const totalLineText = (): string => {
    const minTotalText = isAnnualPrice ? 'Min. Yearly Total' : 'Min. Monthly Total';
    return SHOW_MODULE_PRICE_PRODUCT_ID.includes(productId) ? minTotalText : 'Total';
  };

  const getProductPrice = (product: OrderItem, index: number) => {
    const isPromotion = hasPromotionName && product.discountAmount !== 0;
    const priceContainerClass = isPromotion ? 'product-price-container-strike-through' : 'product-price-container';

    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
          <div>
            <h3 className='product-name-container' key={`displayName-${index}`}>
              {product.displayName}
            </h3>
          </div>
          <div
            className={priceContainerClass}
            data-testid={priceContainerClass}
            key={`totalPrice-${index}`}
            style={{ textAlign: 'right', flexGrow: 0.8 }}
          >
            ${isAnnualPrice && !isPromotion ? getDiscountPrice(product, isAnnualPrice, region) :
                getListPrice(product, isAnnualPrice, region)} {product.currency}
          </div>
        </div>
      </div>
    );
  };

  const getModuleProductPriceText = () => {
    const moduleProductPrice = formatToTwoDecimalPlaces(
      getItemPrice(moduleProductData.items[0], isNzPPLProduct(productId))
    );
    const gstDescription = isNzPPLProduct(productId) ? ' + GST' : '';
    return `$${moduleProductPrice}/month per employee paid${gstDescription} `;
  };

  const getModuleProductPrice = () => (
    <div style={{ fontSize: '14px' }}>
      {moduleProductData.items && showModulePrice(productId) &&
      <div style={{
        paddingTop: '1px', display: 'flex', justifyContent: 'flex-end'
      }}
      >
        <div className='module-product-price-container'>
          + {getModuleProductPriceText()}
          {getTooltip(productData.items[0], getModuleProductPriceText())}
        </div>
      </div>
      }
    </div>
  );

  const getNzPayrollOnlyProductInformation = () => (
    <div className='product-price-information'>
      <div className='flex-row'>
        <div className='information-part'>
          <h3>Payroll only - base product</h3>
        </div>
        <div className='price-part'>
          <div className='price'>
            ${formatToTwoDecimalPlaces(productData.items[0].listPriceWithoutTax)} {productData.currency}
          </div>
          <div className='unit'>per month + GST</div>
        </div>
      </div>
      <Separator className='feature-separator'/>
      <div className='flex-row'>
        <div className='information-part'>
          <h3>Plus an additional cost per employee</h3>
          <div className='additional-info'>*Cost is calculated per each employee paid
            during the month and added to your monthly invoice</div>
        </div>
        <div className='price-part'>
          <div className='price'>
            *${formatToTwoDecimalPlaces(getItemPrice(moduleProductData.items[0],
              isNzPPLProduct(productId)))} {productData.currency}
          </div>
          <div className='unit'>per employee + GST</div>
        </div>
      </div>
    </div>);

  return (
    <>
      {productId === NZ_PAYROLL_ONLY_PRODUCT_ID ? getNzPayrollOnlyProductInformation() : getProductInformation()}
    </>
  );
};
