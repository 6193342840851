import React from 'react';
import { Heading, Text } from '@myob/myob-widgets';

export const ProductTitle = (props: { productName: string }): React.JSX.Element => (
  <div
    className='product-title'
    data-testid='product-title'
  >
    <Text
      className='product-summary'
      data-testid='product-summary'
      as='span'
      display='block'
      tone='neutralStrongest'
      fontSize='sm'
    >
      Summary of your plan
    </Text>
    <Heading
      className='product-name'
      data-testid='product-name'
    >
      {props.productName}
    </Heading>
  </div>);
