import React, { useEffect, useState } from 'react';
import './EmailInputForm.scss';
import {
  Button, Input, MYOBLogo, PageHead, Box, TagIcon, Pill, Text, ButtonLink, TickIcon, ErrorIcon
} from '@myob/myob-widgets';
import { useForm } from 'react-hook-form';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';

import { get } from 'lodash';
import {
  ANALYTICS_ID,
  COLOURS_AS_HEX,
  NINETAILED_ANALYTICS_CONSTANTS,
  REGULAR_EXPRESSIONS
} from '../../constants';
import '../../app/App.scss';
import { getTerms } from '../terms/Terms';
import { useBeginCheckoutEvent } from '../../hooks/GTMAnalytics/useGTMAnalytics';
import { useBeginCheckoutEventGA4 } from '../../hooks/GTM4Analytics/useGTMAnalytics';
import { useCheckoutStarted } from '../../hooks/Analytics/useAnalytics';
import { useSegmentContext } from '../../contexts/segmentProvider/SegmentProvider';
import { usePromoBoxFeatureContext } from '../featureToggle/PromoCodeBoxFeatureToggle';
import { useProductData } from '../../hooks/useProductData/useProductData';

export const EmailInputForm: React.FC<{
  handleEmailChecked: (emailValue: string) => void
  pagerHeaderTitle: string
  submitButtonText: string
  isError: boolean
}> = ({
  handleEmailChecked, pagerHeaderTitle, submitButtonText, isError
}) => {
  const {
    phoneNumber, promoCode, promotionEndDate
  } = useSessionContext();

  const isSegmentLibraryReady = useSegmentContext();
  const isPromoBoxFeatureEnabled = usePromoBoxFeatureContext();
  const { productData } = useProductData();
  const productItems = get(productData, 'items');
  const offerText = productItems[0].deal?.promotionName;

  const {
    register,
    handleSubmit,
    errors
  } = useForm({ mode: 'onTouched' });

  const [disabled, setDisableValue] = useState(false);
  const [isValidPromoCode, setIsValidPromoCode] = useState<boolean | null>(false);
  const [inputPromoCode, setInputPromoCode] = useState(promoCode && offerText ? promoCode : '');
  const [showPromoBox, setShowPromoBox] = useState(false);

  const validatePromoCode = () => {
    // if (!inputPromoCode && (!promoCode || (promoCode && !offerText))) {
    if (!inputPromoCode && !offerText) {
      setIsValidPromoCode(null);
    } else if (inputPromoCode !== promoCode) {
      setIsValidPromoCode(false);
    } else {
      setIsValidPromoCode(true);
    }
  };

  useEffect(() => {
    validatePromoCode();
  }, []);

  const handlePromoCodeLinkClick = () => {
    setShowPromoBox(true);
  };
  useBeginCheckoutEvent();
  useCheckoutStarted(isSegmentLibraryReady);
  useBeginCheckoutEventGA4(isSegmentLibraryReady);
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.BEGIN_CHECKOUT);

  return (
    <div data-testid='email-input-form' className={'feature-box-right'}>
      <div style={{ width: '6.25em', marginBottom: '1em' }}>
        <MYOBLogo />
      </div>
      <PageHead title={pagerHeaderTitle}/>
      <div style={{ width: '20em' }}>
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_EMAIL}
          name='email'
          label='Email*'
          reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.EMAIL })}
          disabled={disabled}
          errorMessage={
            errors.email &&
                (errors.email.type === 'required' ? 'Email is required' : 'Email is invalid')}
        />
        {isError &&
          <p style={{ marginTop: '1rem', color: COLOURS_AS_HEX.Berry }}>
            Something went wrong. If the issue persists, contact MYOB support on{' '}
            {phoneNumber.salesPhoneNumber}
          </p>
        }
        {isPromoBoxFeatureEnabled && !showPromoBox && (
          <div className='promo-code-link-container'>
            <ButtonLink className={'promo-code-link'} onClick={handlePromoCodeLinkClick}>
              Have a promotional code?
            </ButtonLink>
          </div>
        )}
        {isPromoBoxFeatureEnabled && showPromoBox && (
          <Box className='promo-code-group' data-testid='promo-code-group' backgroundColor='#F0F0F0'>
            <Text className='promo-code-input-label' as='span' fontSize='sm' fontWeight='medium'>
              Apply promotional code
            </Text>
            <Box className='promo-code-input-text'>
              <Input
                className={`promo-code-input-box ${isValidPromoCode}`}
                data-testid='promo-code-input'
                containerClassName='promo-code-input-container'
                hideLabel={true}
                name='promoCode'
                value={inputPromoCode}
                suffixIcon={(isValidPromoCode &&
                <TickIcon className='promo-code-input-tick-icon'/>) ||
                    (isValidPromoCode === false &&
                    <ErrorIcon className='promo-code-input-error-icon' color='#CC0000'/>)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputPromoCode(e.target.value);
                }}
              />
              <Button
                className='promo-code-apply-button'
                data-testid='promo-code-apply-button'
                onClick={() => {
                  validatePromoCode();
                }}
              >
                Apply
              </Button>
            </Box>
            {isValidPromoCode !== null && (
              <div>
                <p className={`promo-code-verification-text ${isValidPromoCode}`}>
                  {isValidPromoCode ? 'Promotional code applied' : 'Invalid promotional code'}
                </p>
                {offerText &&
                  <Pill label={offerText} prefixAccessory={<TagIcon color={'brand'} />} className={'promo-code-tag'}/>
                }
              </div>
            )}
          </Box>
        )}
        <Button
          id={ANALYTICS_ID.BUTTON_EXISTING_USER_CHECK}
          onClick={handleSubmit((data) => {
            setDisableValue(true);
            handleEmailChecked(data.email);
          })}
          disabled={disabled}
        >
          {submitButtonText}
        </Button>
        {getTerms('terms', promoCode, promotionEndDate)}
      </div>
    </div>
  );
};
