import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import addScript from 'helpers/addScript/addScript';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from '@myob/myob-widgets';
import { QueryClient, QueryClientProvider } from 'react-query';
import Auth0 from 'hooks/Auth0/Auth0';
import NinetailedWrapper from 'components/NinetailedWrapper/NinetailedWrapper';
import { PromoCodeBoxFeatureToggleProvider } from 'components/featureToggle/PromoCodeBoxFeatureToggle';
import { ANALYTICS_CONSTANTS } from './constants';
import SegmentProvider from './contexts/segmentProvider/SegmentProvider';
import { SessionProvider } from './contexts/sessionProvider/SessionProvider';

import App from './app/App';

const history = createBrowserHistory();

addScript(
  `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
);
TagManager.initialize({
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  dataLayerName: 'CheckoutDataLayer'
});

history.listen(() => {
  const windowRef: any = window;

  windowRef.dataLayer.push({
    event: ANALYTICS_CONSTANTS.ROUTE_CHANGE_EVENT
  });
});

const rootElement = window.document.getElementById('checkout-ui-root');
const isIE = /* @cc_on!@ */false || !!document.documentMode;
const isAtLeastIE11 =
  !!(navigator.userAgent.match(/Trident/) && !navigator.userAgent.match(/MSIE/));

const OutdatedBrowserWarning = () => (
  <section className='outdated-browser fg-light' data-myob-outdated-browser data-closable>
    <div className='outdated-browser__body'>
      <div className='outdated-browser__copy'>Hmm, your browser&apos;s a little old.{' '}
        <a href='https://outdatedbrowser.com' target='_blank' rel='noreferrer'>
          Update here
        </a>
        {' '}for the best experience.
      </div>
    </div>
  </section>
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const ComponentRender = (isIE && !isAtLeastIE11) ? (
  <OutdatedBrowserWarning/>
) : (
  <BrowserRouter>
    <SessionProvider>
      <SegmentProvider>
        <ThemeProvider theme='modern'>
          <NinetailedWrapper>
            <QueryClientProvider client={queryClient}>
              <Auth0 audience='quipu-api'>
                <PromoCodeBoxFeatureToggleProvider>
                  <App/>
                </PromoCodeBoxFeatureToggleProvider>
              </Auth0>
            </QueryClientProvider>
          </NinetailedWrapper>
        </ThemeProvider>
      </SegmentProvider>
    </SessionProvider>
  </BrowserRouter>
);

ReactDOM.render(
  ComponentRender,
  rootElement
);
