import axios from 'helpers/axios/axios';
import config from 'Config';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

type UseRecaptchaType = {
  isLoading: boolean;
  success: boolean | undefined;
}

export const useRecaptcha = ({
  enabled: enabledProp, action, identityId, honeypotVar = ''
}: {enabled: boolean, action: string, identityId: string, honeypotVar?: string }): UseRecaptchaType => {
  // for local by pass check for SITE_KEY
  const enabled = enabledProp && Boolean(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  useEffect(() => {
    if (enabled) {
      window.grecaptcha?.ready(async () => {
        const token = await window.grecaptcha.execute(
          process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action }
        );
        setRecaptchaToken(token);
      });
    }
  }, [enabled, action, window.grecaptcha]);

  const { isLoading: queryInFlight, data, isError } = useQuery(
    'recaptcha',
    () => axios.get(config.API_RECAPTCHA_ENDPOINT,
      {
        params: {
          gRecaptchaResponse: recaptchaToken,
          identityId,
          action,
          honeypotVar
        }
      }),
    { enabled: !!recaptchaToken && enabled, retry: false }
  );

  const success = !isError && data?.data?.success;

  if (!enabled) {
    return { isLoading: false, success };
  }

  return { isLoading: !recaptchaToken || queryInFlight, success };
};
