import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { isMobile } from 'react-device-detect';
import { useExistingUserCheck } from '../../hooks/useExistingUserCheck/useExistingUserCheck';
import { useRecaptcha } from '../../hooks/useRecaptcha/useRecaptcha';
import { Main } from '../../common/main/Main';
import { useGetProduct } from '../../hooks/useGetProduct/useGetProduct';
import {
  getPageHeaderTitle,
  getSubmitButtonTextOnAccountDetailsForm,
  getSubmitButtonTextOnEmailInputForm,
  trackCheckoutStarting
} from '../emailPage/EmailPage';
import { EmailInputForm } from '../emailInputForm/EmailInputForm';
import { NewTrialAccountDetailsForm } from '../newTrialAccountDetailsForm/NewTrialAccountDetailsForm';
import { OrderSummary } from '../orderSummary/OrderSummary';
import { SUBSCRIPTION_TYPE } from '../../constants';
import { NewFeatureContainer } from '../newFeatureContainer/NewFeatureContainer';
import ProductCard from '../productCard/ProductCard';

export const NewTrialEmailPage: React.FC = () => {
  const [emailValue, setEmailValue] = useState('');
  const {
    region, productFreePeriod, setHasPurchaseSubmitted
  } = useSessionContext();
  setHasPurchaseSubmitted(false);
  const { isLoading: isProductLoading, isSuccess: fetchProductDataSuccess, productData } = useGetProduct();
  const {
    isNewUser, isEmailChecked, isError, identityId
  } = useExistingUserCheck(emailValue);
  trackCheckoutStarting(isEmailChecked, emailValue); // This function contains React Hooks, never put it in a condition.
  const {
    isLoading: isRecaptchaLoading,
    success: isRecaptchaSuccess
  } = useRecaptcha({ enabled: isEmailChecked, action: 'view', identityId });

  const isLoading = isRecaptchaLoading || isProductLoading;
  if (isLoading) {
    return (<div data-testid='email-page-loading'/>);
  }

  const isProductAndRegionMatched: boolean = fetchProductDataSuccess && productData && region === productData.region;
  if (isRecaptchaSuccess === false || !isProductAndRegionMatched) {
    return <Redirect to='/error' />;
  }

  const renderEmailInputForm = () => {
    const trialDealDuration = productData?.items[0]?.trialDeal?.duration ?? 1;
    if (isEmailChecked) {
      return (
        <React.Fragment>
          <NewTrialAccountDetailsForm
            pageHeaderText={getPageHeaderTitle(SUBSCRIPTION_TYPE.SUBSCRIBE, productFreePeriod, trialDealDuration)}
            submitButtonText={getSubmitButtonTextOnAccountDetailsForm(SUBSCRIPTION_TYPE.SUBSCRIBE, isNewUser)}
            isNewUser={isNewUser}
            emailValue={emailValue}
          />
        </React.Fragment>
      );
    }
    return (
      <EmailInputForm
        handleEmailChecked={setEmailValue}
        pagerHeaderTitle={getPageHeaderTitle(SUBSCRIPTION_TYPE.SUBSCRIBE, productFreePeriod, trialDealDuration)}
        submitButtonText={getSubmitButtonTextOnEmailInputForm(SUBSCRIPTION_TYPE.SUBSCRIBE)}
        isError={isError}
      />
    );
  };

  return (
    <Main>
      <NewFeatureContainer>
        {isMobile ? <OrderSummary /> : <ProductCard />}
      </NewFeatureContainer>
      {renderEmailInputForm()}
    </Main>
  );
};
