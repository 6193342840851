import { get } from 'lodash';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useEffect } from 'react';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { useAuth0 } from '../Auth0/Auth0';
import { useGetAccessToken } from '../useGetAcessToken/useGetAccessToken';
import { useGetProduct } from '../useGetProduct/useGetProduct';
import { useFormCompleted, useFormSubmit } from '../Analytics/useAnalytics';
import { ANALYTICS_ID, REQUEST_HEADERS } from '../../constants';
import axios from '../../helpers/axios/axios';
import config from '../../Config';
import { useRecaptcha } from '../useRecaptcha/useRecaptcha';

type TrialResponse = {
  productInstanceId: string;
  purchaseId: string;
  serialNumber: string;
}

type UseTrialCreation = {
  isLoading: boolean;
  isError: boolean;
  orderNumber: string;
  isSuccess: boolean;
}

export const useTrialCreation = (accountId: string | undefined): UseTrialCreation => {
  const { productId, region } = useSessionContext();
  const {
    isLoading: isAuth0Loading, user, appState, error: isAuth0Error
  } = useAuth0();
  const { accessToken } = useGetAccessToken();
  const { isLoading: isProductDataLoading, productData, isError: isProductDataError } = useGetProduct();

  const businessName = get(appState, 'clientInformation.businessName');
  const contactIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  const dealId = get(productData, 'items[0].trialDeal.id');
  const honeypotVar = get(appState, 'clientInformation.website');

  const advertisedPromotionId = get(productData, 'items[0].deal.promotionId');

  useFormSubmit({ elementId: ANALYTICS_ID.TRIAL_FORM });

  const trialPayload = {
    identity_id: contactIdentityId,
    account_id: accountId,
    billing_account_id: accountId,
    deal_id: dealId,
    product_id: productId,
    account_name: businessName,
    region,
    advertised_promotion_id: advertisedPromotionId
  };
  const { hasPurchaseSubmitted, setHasPurchaseSubmitted } = useSessionContext();

  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const {
    isLoading: isRecaptchaLoading,
    success: isRecaptchaSuccess
  } = useRecaptcha({
    enabled: true, action: 'submit', identityId: contactIdentityId, honeypotVar
  });

  const {
    isLoading: isTrialLoading,
    isError: isTrialError,
    isSuccess,
    data
  } = useQuery(
    'trial',
    () => axios.post<TrialResponse>(
      `${config.API_TRIAL_ENDPOINT}`,
      qs.stringify(trialPayload),
      options
    ),
    {
      enabled: !!productData && !!accessToken && !hasPurchaseSubmitted &&
        !!accountId && !!isRecaptchaSuccess,
      retry: false
    }
  );

  const { setOrderNumber } = useSessionContext();
  useEffect(() => {
    if (isSuccess) {
      setOrderNumber(data?.data.purchaseId as string);
    }
  }, [isSuccess]);

  useFormCompleted({ elementId: ANALYTICS_ID.TRIAL_FORM, isError: isTrialError });

  useEffect(() => {
    if (isSuccess || isTrialError) {
      setHasPurchaseSubmitted(true);
    }
  }, [isSuccess, isTrialError]);

  const isLoading = isAuth0Loading || isProductDataLoading || isTrialLoading || isRecaptchaLoading;
  const isError = isProductDataError || isTrialError || !!isAuth0Error || isRecaptchaSuccess === false;
  const orderNumber = data?.data.purchaseId as string;
  return {
    isError, isLoading, orderNumber, isSuccess
  };
};
