import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SubscribeConfirmation } from 'components/confirmation/subscribeConfirmation/SubscribeConfirmation';
import { EmailPage } from '../components/emailPage/EmailPage';
import { PaymentPage } from '../components/paymentPage/PaymentPage';
import DuplicateTrial from '../components/duplicateTrial/DuplidateTrial';
import { ErrorPage } from '../components/errorPage/ErrorPage';
import { NewTrialEmailPage } from '../components/NewTrialEmailPage/NewTrialEmailPage';
import NewTrialConfirmation from '../components/confirmation/newTrialConfirmation/NewTrialConfirmation';
import { TrialConfirmation }
  from '../components/confirmation/trialConfirmation/TrialConfirmation';

const App: React.FC = () => (
  <Switch>
    <Route path='/:region/trial' exact>
      <EmailPage/>
    </Route>
    <Route path='/:region/subscribe' exact>
      <EmailPage/>
    </Route>
    <Route path='/:region/subscribe/annual' exact>
      <EmailPage/>
    </Route>
    <Route path='/:region/new-trial' exact>
      <NewTrialEmailPage/>
    </Route>
    <Route path='/:region/subscribe/payment'>
      <PaymentPage/>
    </Route>
    <Route path='/:region/subscribe/payment/annual'>
      <PaymentPage/>
    </Route>
    <Route path='/:region/subscribe/confirmation'>
      <SubscribeConfirmation/>
    </Route>
    <Route path='/:region/subscribe/confirmation/annual'>
      <SubscribeConfirmation/>
    </Route>
    <Route path='/:region/trial/duplicate'>
      <DuplicateTrial/>
    </Route>
    <Route path='/:region/trial/confirmation'>
      <TrialConfirmation/>
    </Route>
    <Route path='/:region/new-trial/confirmation'>
      <NewTrialConfirmation/>
    </Route>
    <Route path='/error'>
      <ErrorPage/>
    </Route>
    <Route>
      <ErrorPage/>
    </Route>
  </Switch>
);

export default App;
