import React from 'react';
import { Input, Spinner } from '@myob/myob-widgets';
import { DeepMap, FieldError } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { PROMOTION_CODE, REGULAR_EXPRESSIONS } from '../../../constants';
import { DirectDebitDetail } from '../types';
import './directDebitForm.scss';
import { trimFocusEventInputValue } from '../../../helpers/trim/trim';

export const DirectDebitContainerAU: React.FC<{
  register:any,
  errors:DeepMap<DirectDebitDetail, FieldError>,
  validateBankCode: (bankCode: string) => Promise<boolean>,
  promoCode: string,
  isValidateBankCodeError: boolean,
  isValidateBankCodeLoading: boolean,
}> = ({
  register,
  errors,
  validateBankCode,
  promoCode,
  isValidateBankCodeError,
  isValidateBankCodeLoading
}) => {
  if (isValidateBankCodeError) {
    return <Redirect to='/error'/>;
  }

  return (
    <>
      <Input
        className={'input__field'}
        id='bsb'
        name='bsb'
        label='BSB number*'
        maxLength={6}
        reference={register({
          required: true,
          pattern: REGULAR_EXPRESSIONS.BSB,
          validate: {
            invalidBsb: validateBankCode,
            invalidWestpacBsb: (bankCode: string) =>
              promoCode !== PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE ||
                      bankCode.startsWith('73') || bankCode.startsWith('03'),
            invalidStgBsb: (bankCode: string) =>
              promoCode !== PROMOTION_CODE.CODE_WESTPAC_STG ||
                      bankCode.startsWith('19') || bankCode.startsWith('11'),
            invalidBomBsb: (bankCode: string) =>
              promoCode !== PROMOTION_CODE.CODE_WESTPAC_BOM ||
                      bankCode.startsWith('19'),
            invalidBankSaBsb: (bankCode: string) =>
              promoCode !== PROMOTION_CODE.CODE_WESTPAC_BANKSA ||
                      bankCode.startsWith('10')
          }
        })}
        errorMessage={ errors.bsb && generateBsbNumberErrorMsg(errors.bsb.type) }
      />
      {isValidateBankCodeLoading &&
      <div id='bsb-loading-state' className='flex mt-min'>
        <Spinner data-testid='spinner1' size='small'/>
        <small className='ml-min loading-font'>Validating BSB number...</small>
      </div>
      }
      <Input
        className={'input__field'}
        id='accountNumber'
        name='accountNumber'
        label='Account Number*'
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NUMBER })}
        errorMessage={
          errors.accountNumber &&
                    (errors.accountNumber.type === 'required' ?
                      'Account number is required' : 'Invalid account number')}
      />
      <Input
        className={'input__field'}
        id='accountName'
        name='accountName'
        label='Account Name*'
        onBlur={trimFocusEventInputValue}
        reference={register({ required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NAME })}
        errorMessage={
          errors.accountName &&
                    (errors.accountName.type === 'required' ?
                      'Account name is required' : 'Please enter a valid account name')}
      />
      <br />
    </>
  );
};

const generateBsbNumberErrorMsg = (errorType: string): string => {
  switch (errorType) {
    case 'required': return 'BSB is required';
    case 'invalidWestpacBsb': return 'Please add a valid Westpac Business One bank account';
    case 'invalidStgBsb': return 'Please add a valid St.George bank account';
    case 'invalidBomBsb': return 'Please add a valid Bank of Melbourne bank account';
    case 'invalidBankSaBsb': return 'Please add a valid Bank of SA account';
    default: return 'Invalid BSB';
  }
};
