import { v4 as uuidv4 } from 'uuid';
import { CHECKOUT_VISITOR_ID } from '../constants';
import { generateSha256Hex } from './HashGenerator';

export const areCookiesEnabled = (): boolean => {
  // Try to set a test cookie
  document.cookie = 'testcookie';

  // Check if the test cookie has been set
  const cookiesEnabled = document.cookie.indexOf('testcookie') !== -1;

  // Clean up the test cookie
  document.cookie = 'testcookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

  return cookiesEnabled;
};

export const areCookiesDisabled = (): boolean => !areCookiesEnabled();
export const isEmpty = (value: string | undefined): boolean => (value === undefined || value.trim() === '');

const getVisitorId = (): string => {
  if (!localStorage.getItem(CHECKOUT_VISITOR_ID)) {
    localStorage.setItem(CHECKOUT_VISITOR_ID, uuidv4());
  }

  return localStorage.getItem(CHECKOUT_VISITOR_ID) as string;
};

export const getHashForVisitorId = (bucketSize = 100): number => {
  const visitorId = getVisitorId();
  const hashedVisitorId = generateSha256Hex(visitorId);
  const hashInt = BigInt(`0x${hashedVisitorId}`);
  return Number(hashInt % BigInt(bucketSize));
};
