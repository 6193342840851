import React, {
  createContext, useContext, useEffect, useMemo
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { isMobile } from 'react-device-detect';
import { getHashForVisitorId } from 'utils/utility';
import useFeatureToggle from 'hooks/useFeatureToggle/useFeatureToggle';
import {
  FEATURE_TOGGLE,
  NZ_PAYROLL_ONLY_PRODUCT_ID,
  REGIONS,
  SUBSCRIPTION_TYPE
} from '../../constants';

const AB_TEST_PERCENTAGE = 50;
const EMPTY_BOX = <div data-testid='test-empty-box' />;
const APPLICABLE_PRODUCT_IDS = [NZ_PAYROLL_ONLY_PRODUCT_ID, '76', '78'];
const isABGroupApplicable = () => getHashForVisitorId() < AB_TEST_PERCENTAGE;
const initialChecks = (pathname: string, { productId }: any) => {
  const [region, subscriptionType, anyOther] = pathname.split('/').slice(1);
  return region === REGIONS.NEW_ZEALAND.toLowerCase() &&
    !isMobile &&
    subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE &&
    APPLICABLE_PRODUCT_IDS.includes(productId) &&
    !anyOther;
};

export const PromoCodeFeatureContext = createContext(false);
export const usePromoBoxFeatureContext = (): boolean => useContext(PromoCodeFeatureContext);

export const PromoCodeBoxFeatureToggleProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { search, pathname } = location;
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true, decoder: (c) => c });

  const featureEnabled = useFeatureToggle(FEATURE_TOGGLE.PROMO_BOX_FEATURE_TOGGLE);
  const isFeatureApplicable = useMemo(() =>
    featureEnabled &&
    isABGroupApplicable() &&
    initialChecks(pathname, queryParams),
  [featureEnabled, pathname, queryParams]);

  const urlUpdated = isFeatureApplicable ? queryParams.grouping === 'applied' : queryParams.grouping === undefined;

  const isReady = !isFeatureApplicable || urlUpdated;

  useEffect(() => {
    if (urlUpdated) return;

    history.replace({
      search: qs.stringify({
        ...queryParams,
        grouping: isFeatureApplicable ? 'applied' : undefined
      }, { encoder: (str) => str })
    });
  }, [isFeatureApplicable, queryParams, history, urlUpdated]);

  return (
    <PromoCodeFeatureContext.Provider value={isFeatureApplicable}>
      {isReady ? children : EMPTY_BOX}
    </PromoCodeFeatureContext.Provider>
  );
};
