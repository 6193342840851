import React from 'react';
import { MYOBLogo, Spinner } from '@myob/myob-widgets';
import { ANALYTICS_ID } from '../../constants';
import { Main } from '../../common/main/Main';
import ProgressButton from './progressButton/ProgressButton';
import Config from '../../Config';
import { NewFeatureContainer } from '../newFeatureContainer/NewFeatureContainer';

interface ConfirmationProps {
  featureList: React.ReactElement,
  isLoading: boolean,
  stepper?: React.ReactElement,
  pageTitle?: string,
  mainDescription?: React.ReactElement,
  terms?: React.ReactElement
}

const Confirmation: React.FC<ConfirmationProps> = ({
  featureList, stepper, isLoading, pageTitle, mainDescription, terms
}) => (
  <Main>
    <NewFeatureContainer>
      {featureList}
    </NewFeatureContainer>
    <div className={'feature-box-right'}>
      {stepper}
      <div style={{ width: '6.25em', marginBottom: '1em' }}>
        <MYOBLogo />
      </div>
      {isLoading ?
        <Spinner size='small' data-testid='spinner'/> :
        <div className='confirmation-page-container'>
          <h1 className='confirmation-page-title'>{pageTitle}</h1>
          <div className='confirmation-page-main'>
            {mainDescription}
          </div>
          <ProgressButton
            id={ANALYTICS_ID.BUTTON_ACCESS_SOFTWARE}
            startText='Setting up your software'
            endText='Access your software now'
            duration={Config.DEEP_LINKING.BUTTON_DISABLE_TIME}
            href={Config.PRODUCT_URL}
          />
          {terms}
        </div>
      }
    </div>
  </Main>
);

export default Confirmation;
