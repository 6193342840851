import { Button } from '@myob/myob-widgets';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Config from '../../../Config';
import { CreditCardDetail, PCIPortholeData } from '../types';
import PaymentTerms from '../../paymentTerms/PaymentTerms';
import {
  COLOURS_AS_HEX,
  PAYMENT_TERMS_ERROR_MESSAGE,
  PROMOTION_CODE,
  WESTPAC,
  WESTPAC_DIGITS_RELATION
} from '../../../constants';
import { useSessionContext } from '../../../contexts/sessionProvider/SessionProvider';
import { useCreditCardValidator } from '../../../hooks/useCreditCardValidator/useCreditCardValidator';

export const CreditCardForm: React.FC<{
  isPaymentProfileCreationLoading: boolean,
  setCreditCardDetail: React.Dispatch<React.SetStateAction<CreditCardDetail>>,
  createPaymentProfile: () => void
}> = ({ isPaymentProfileCreationLoading, setCreditCardDetail, createPaymentProfile }) => {
  const PROCESS_CREDIT_CARD_ACTION = 'processCreditCard';
  const { promoCode } = useSessionContext();

  const [isTermsCheck, setIsTermsCheck] = React.useState(false);

  const [termsErrorMessage, setTermsErrorMessage] = React.useState('');

  const postProcessCreditCardEvent = () => {
    if (isTermsCheck) {
      const event = { action: PROCESS_CREDIT_CARD_ACTION };
      window.cardIframeProxy.post(event);
    } else {
      setTermsErrorMessage(PAYMENT_TERMS_ERROR_MESSAGE);
    }
  };

  const [firstSixDigits, setFirstSixDigits] = useState('');
  const [isValidCreditCard, setIsValidCreditCard] = useState(false);
  const [showWestpacCardError, setShowWestpacCardError] = useState(false);

  const {
    isLoading: isCreditCardValidationLoading,
    isError: isCreditCardValidationError,
    refetch
  } = useCreditCardValidator(firstSixDigits, 'westpac');

  useEffect(() => {
    if (isValidCreditCard) {
      createPaymentProfile();
    }
  }, [isValidCreditCard]);

  const isPromotionIsWestpacAcc = () => Object.keys(WESTPAC_DIGITS_RELATION).includes(promoCode);

  const handleCardPortalMessage = useCallback(
    (ev: WindowEventMap['message']): void => {
      const checkCard = () =>
        refetch().then((result) => result.data?.data.validFirstSixDigits);

      const validateWestpacBusinessAcc = (bankCode: number) =>
        WESTPAC_DIGITS_RELATION[PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE].includes(bankCode);

      if (Config.PAYMENT_PCI_URL.startsWith(ev.origin)) {
        const event = ev.data as PCIPortholeData;
        if (event.outcome.validation.valid) {
          const firstSixDigits = event.outcome.cardDetails.maskedNumber.slice(0, 6);
          setCreditCardDetail(event.outcome.cardDetails);
          setFirstSixDigits(firstSixDigits);
          if (isPromotionIsWestpacAcc()) {
            const isValidWestpacCard = validateWestpacBusinessAcc(Number(firstSixDigits));
            setIsValidCreditCard(isValidWestpacCard);
            setShowWestpacCardError(!isValidWestpacCard);
          } else if (Object.keys(WESTPAC).includes(promoCode)) {
            checkCard().then((isValidWestpacCard) => {
              setIsValidCreditCard(isValidWestpacCard);
              setShowWestpacCardError(!isValidWestpacCard);
            });
          } else {
            setIsValidCreditCard(true);
          }
        }
      }
    }, []
  );

  const setTerms = () => {
    setIsTermsCheck(!isTermsCheck);
    setTermsErrorMessage('');
  };

  useEffect(() => {
    window.cardIframeProxy = new Porthole.WindowProxy(
      Config.CARD_PORTAL_PROXY_URL, 'cardPortalFrame'
    );

    window.cardIframeProxy.addEventListener(handleCardPortalMessage);

    return (): void => {
      window.cardIframeProxy.removeEventListener(handleCardPortalMessage);
    };
  }, [handleCardPortalMessage]);

  if (isCreditCardValidationError) {
    return <Redirect to='/error'/>;
  }

  return (
    <div>
      <div style={{
        width: '20em', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'
      }}
      >
        <iframe
          data-testid='cardPortalFrame'
          id='cardPortalFrame'
          style={{ minHeight: '350px', border: 0, overflow: 'hidden' }}
          sandbox='allow-same-origin allow-scripts'
          name='cardPortalFrame'
          src={Config.PAYMENT_PCI_URL}
          scrolling='no' // Can't remove, cuz dest page overflow. https://stackoverflow.com/questions/15494568
          seamless
          title='cardPortalFrame'
        />
        {showWestpacCardError && <WestpacCardError promoCode ={promoCode}/>}
        <PaymentTerms
          isChecked={isTermsCheck}
          errorMessage={termsErrorMessage} setTerms={setTerms}
        />
        <Button
          onClick={postProcessCreditCardEvent}
          disabled={isPaymentProfileCreationLoading || isCreditCardValidationLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

const WestpacCardError: React.FC<{ promoCode: string }> = ({ promoCode }) =>
  <div style={{ width: '28em', color: COLOURS_AS_HEX.Berry, marginBottom: '2em' }}>
    <span>Please add a valid {WESTPAC[promoCode as string]} Business debit or credit card</span>
  </div>;
