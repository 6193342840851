import axios from 'helpers/axios/axios';
import config from 'Config';
import { useQuery } from 'react-query';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../Auth0/Auth0';
import { ProductData } from './types';

type UseGetProductType = {
  productData: ProductData,
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

export const useGetProduct = (): UseGetProductType => {
  const history = useHistory();
  const { isLoading: isAuth0Loading } = useAuth0();
  const {
    productId, promoCode, isInitialised, isAnnualPrice
  } = useSessionContext();

  let presentmentUrl = `${config.API_PRODUCT_PRICE_ENDPOINT}?productId=${productId}`;
  if (promoCode) {
    presentmentUrl += `&promoCode=${promoCode}`;
  }
  presentmentUrl += isAnnualPrice ? '&paymentSchedule=ANNUAL' : '';

  const {
    isLoading: isProductDataLoading, isError, data, isSuccess
  } = useQuery(
    ['productData', productId, promoCode],
    () => axios.get(presentmentUrl),
    {
      enabled:
        !isAuth0Loading &&
        isInitialised &&
        Boolean(productId),
      retry: false
    }
  );

  if (isError) {
    history.push('/error');
  }
  const isLoading = !isInitialised || isProductDataLoading || isAuth0Loading;

  const productData = data?.data || {} as ProductData;
  return {
    productData, isLoading, isError, isSuccess
  };
};
