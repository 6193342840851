export const REGIONS = {
  AUSTRALIA: 'AU',
  NEW_ZEALAND: 'NZ'
};

export const PRICING_LIST_URL = {
  AU: 'https://www.myob.com/au/pricing',
  NZ: 'https://www.myob.com/nz/pricing'
};

export const SUPPORTED_ANNUAL_PRICE_PRODUCTS = {
  AU: ['77', '79']
};

export const EXCLUDED_PRODUCTS = {
  AU: {
    ESSENTIALS_PAYROLL: '30',
    ESSENTIALS_ACCOUNTING_STARTER: '16',
    ESSENTIALS_ACCOUNTING: '7',
    ESSENTIALS_ACCOUNTING_PLUS_PAYROLL: '11',
    ESSENTIALS_SMEP_PAYROLL: '54',
    ESSENTIALS_SMEP_CONNECTED_LEDGER: '56',
    ESSENTIALS_SMEP_CONNECTED_LEDGER_WITH_PAYROLL: '60',
    ESSENTIALS_SMEP_ACCOUNTING_STARTER: '58',
    ESSENTIALS_SMEP_ACCOUNTING: '50',
    ESSENTIALS_SMEP_ACCOUNTING_WITH_PAYROLL: '52',
    ESSENTIALS_ACCOUNTING_WITH_PAYROLL: '14',
    ESSENTIALS_CONNECTED_LEDGER: '31'
  },
  NZ: {
    ESSENTIALS_ACCOUNTING_STARTER: '17',
    ESSENTIALS_ACCOUNTING: '8',
    ESSENTIALS_ACCOUNTING_PLUS_PAYROLL: '12',
    ESSENTIALS_SMEP_PAYROLL: '55',
    ESSENTIALS_SMEP_CONNECTED_LEDGER: '57',
    ESSENTIALS_SMEP_CONNECTED_LEDGER_WITH_PAYROLL: '61',
    ESSENTIALS_SMEP_ACCOUNTING_STARTER: '59',
    ESSENTIALS_SMEP_ACCOUNTING: '51',
    ESSENTIALS_SMEP_ACCOUNTING_WITH_PAYROLL: '53',
    ESSENTIALS_CONNECTED_LEDGER: '15',
    ESSENTIALS_PAYROLL: '13',
    ESSENTIALS_CONNECTED_LEDGER_WITH_PAYROLL: '32'
  }
};

export const PROMOTION_CODE = {
  CODE_ONLINEONLY50PCTOFF: 'ONLINEONLY50PCTOFF',
  CODE_WESTPAC: 'WESTPAC',
  CODE_GODADDY: 'GODADDY',
  CODE_WESTPAC_BOM: 'BOM',
  CODE_WESTPAC_BANKSA: 'BANKSA',
  CODE_WESTPAC_STG: 'STG',
  CODE_WESTPACBUSINESSACC_12MTH_FREE: 'WESTPACBUSINESSACC-12MTH-FREE',
  CODE_CUP_30PCTOFF_LIFETIME: 'CUP-30PCTOFF-LIFETIME',
  CODE_BF70PCTOFF3MTHS: 'BF70PCTOFF3MTHS',
  CODE_CPMYOBB50OFFAU: 'CPMYOBB50OFFAU',
  CODE_CPMYOBB50OFFNZ: 'CPMYOBB50OFFNZ',
  CODE_NZEOFY2023: 'NZEOFY2023'
};

export const WESTPAC = {
  [PROMOTION_CODE.CODE_WESTPAC]: 'Westpac',
  [PROMOTION_CODE.CODE_WESTPAC_BOM]: 'Bank of Melbourne',
  [PROMOTION_CODE.CODE_WESTPAC_BANKSA]: 'BankSA',
  [PROMOTION_CODE.CODE_WESTPAC_STG]: 'St. George’s Bank',
  [PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE]: 'Westpac'
};
export const REGIONS_PATH_ARRAY = [
  REGIONS.AUSTRALIA.toLowerCase(),
  REGIONS.NEW_ZEALAND.toLowerCase()
];

export const COLOURS_AS_HEX = {
  Berry: '#CE2554',
  Golf: '#00AA65',
  Regal: '#6100a5',
  Dusk: '#8241aa',
  Candy: '#ec0677',
  Storm100: '#2e3e4f',
  Storm50: '#969ea7',
  White: '#ffffff'
};

export const REGULAR_EXPRESSIONS = {
  EMAIL: /(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/,
  PHONE: /^[\s\d+\-()]{6,40}$/,
  BSB: /^\d{6}$/,
  ACCOUNT_NUMBER: /^\d{1,10}$/,
  ACCOUNT_NAME: /^[A-Za-z\d_.'\-\s]{1,100}$/,
  BUSINESS_NAME: /^.{1,100}$/,
  FIRST_NAME: /^.{1,50}$/,
  LAST_NAME: /^.{1,50}$/,
  NO_ONLY_SPACE: /^(?!\s+$)/,
  BANK: /^\d{2}$/,
  BRANCH: /^\d{4}$/,
  ACCOUNT: /^\d{7}$/,
  SUFFIX: /^\d{2,3}$/
};

export enum TRANSACTION_TYPE {
  TRIAL = 'TRIAL',
  DIRECT_PURCHASE = 'NON_TRIAL_BUY',
  TRIAL_TO_BUY = 'TRIAL_TO_BUY',
  CHANGE_PLAN = 'CHANGE_PLAN',
  ADD_TRIAL = 'ADD_TRIAL'
}

export enum SOURCE_INITIATIVE {
  WEBSITE = 'SHOPPINGCART',
  PRODUCT = 'INAPPCART'
}

export enum SOURCE_SYSTEM {
  WEBSITE = 'ShoppingCart',
  PARTNER = 'PartnerDashboard'
}

export enum PAYMENT_METHOD {
  DEBIT = 'Direct Debit',
  CREDIT = 'Credit Card'
}

export enum CURRENCIES {
  AU = 'AUD',
  NZ = 'NZD'
}

export enum SUBSCRIPTION_TYPE {
  TRIAL = 'trial',
  SUBSCRIBE = 'subscribe',
  NEW_TRIAL = 'new-trial'
}

export const REQUEST_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded'
};

export const LEGAL_LINKS = {
  AU: {
    EA_TERMS_OF_USE: 'https://www.myob.com/au/support/customer-service/myob-legal-notices/EssentialsTermsofUse',
    DIRECT_DEBIT_TERMS_AND_CONDITIONS: 'https://www.myob.com/au/support/customer-service' +
        '/myob-legal-notices/direct-debit-terms-and-conditions-australia',
    PRIVACY_STATEMENT: 'https://www.myob.com/au/privacy-disclosure-statement',
    BANKFEEDS_FAIR_USE_POLICY: 'https://www.myob.com/au/accounting-software/bankfeeds/myob-bankfeeds-fair-use-policy'
  },
  NZ: {
    EA_TERMS_OF_USE: 'https://www.myob.com/nz/support/customer-service' +
        '/myob-legal-notices/EssentialsTermsofUse',
    DIRECT_DEBIT_TERMS_AND_CONDITIONS:
        'https://www.myob.com/nz/support/customer-service/myob-legal-notices/termsofpurchaseNZ',
    PRIVACY_STATEMENT: 'https://www.myob.com/nz/privacy-policy',
    BANKFEEDS_FAIR_USE_POLICY: 'https://www.myob.com/nz/accounting-software/bankfeeds/myob-bankfeeds-fair-use-policy'
  }
};
export const LEGAL_ENTITIES = {
  AU: {
    LEGAL_ENTITY: 'MYOB Australia Pty Ltd'
  },
  NZ: {
    LEGAL_ENTITY: 'MYOB NZ Limited'
  }
};

export const STEP_PATH = {
  TRIAL: {
    ROOT: '/trial',
    DUPLICATE: '/trial/duplicate',
    CONFIRMATION: '/trial/confirmation'
  },
  SUBSCRIBE: {
    ROOT: '/subscribe',
    PAYMENT: '/subscribe/payment',
    PAYMENT_ANNUAL: '/subscribe/payment/annual',
    CONFIRMATION: '/subscribe/confirmation',
    CONFIRMATION_ANNUAL: '/subscribe/confirmation/annual'
  },
  NEW_TRIAL: {
    ROOT: '/new-trial',
    CONFIRMATION: '/new-trial/confirmation'
  }
};

export const PHONE_NUMBERS = {
  AU: {
    SALES_PHONE_NUMBER: '03 9045 4476',
    CUSTOMER_SERVICE_PHONE_NUMBER: '1300 555 111',
    SUPPORT_PHONE_NUMBER: '1300 555 123'
  },
  NZ: {
    SALES_PHONE_NUMBER: '0800 60 69 62',
    CUSTOMER_SERVICE_PHONE_NUMBER: '0508 328 283',
    SUPPORT_PHONE_NUMBER: '0508 328 283'
  }
};

export const ANALYTICS_CONSTANTS = {
  ROUTE_CHANGE_EVENT: 'spRouteChangeSuccess',
  CHECKOUT_STARTED_EVENT: 'checkoutStarted',
  ORDER_COMPLETED_EVENT: 'orderCompleted',
  TRIAL_PURCHASE_EVENT: 'trial-purchase',
  PURCHASE_EVENT: 'purchase',
  AFFILIATION: 'checkout.myob',
  PRODUCT_VARIANT: 'Monthly',
  TRIAL_SUFFIX: ' - Trial',
  FORM_SUBMIT: 'genericFormSubmit',
  FORM_FAIL: 'genericFormFail',
  FORM_SUCCESS: 'genericFormSuccess',
  CONTENT_VIEWED: 'contentViewed'
};

export const GTM_ANALYTICS_CONSTANTS = {
  BEGIN_CHECKOUT: 'begin_checkout',
  BEGIN_CHECKOUT_SEGMENT: 'Begin Checkout',
  ACCOUNT_SETUP: 'account_setup',
  ACCOUNT_SETUP_SEGMENT: 'Account Setup',
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_PAYMENT_INFO_SEGMENT: 'Add Payment Info',
  PURCHASE: 'purchase',
  PURCHASE_SEGMENT: 'Purchase',
  ENHANCED_PURCHASE: 'Enhanced Purchase'
};

export const GTM4_ANALYTICS_CONSTANTS = {
  BEGIN_CHECKOUT: 'begin_checkout',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  CHECKOUT_SIGN_IN: 'checkout_sign_in',
  ADD_PAYMENT_INFO: 'add_payment_info',
  PURCHASE: 'purchase_ga4'
};

export enum GTM_ANALYTICS_EVENTS_CATEGORY {
  SIGN_UP= 'Sign up',
  ADD_BUSINESS= 'Add business'
}

export const ANALYTICS_VARIANTS: { [key in SUBSCRIPTION_TYPE]: string } = {
  [SUBSCRIPTION_TYPE.TRIAL]: 'Trial',
  [SUBSCRIPTION_TYPE.SUBSCRIBE]: 'Non-trial buy',
  [SUBSCRIPTION_TYPE.NEW_TRIAL]: 'New trial'
};

export const NINETAILED_ANALYTICS_CONSTANTS = {
  BEGIN_CHECKOUT: 'begin_checkout',
  PURCHASE: 'purchase'
};

export const ANALYTICS_ID = {
  CONTENT_VIEWED_TRIAL_FORM: 'content-viewed_buy-myob_trial-form',
  CONTENT_VIEWED_TRIAL_CONFIRMATION: 'content-viewed_buy-myob_trial-confirmation',
  CONTENT_VIEWED_SUBSCRIBE_CONFIRMATION: 'content-viewed_buy-myob_subscribe-confirmation',
  TRIAL_FORM: 'form_buy-myob_trial',
  SUBSCRIBE_FORM: 'form_buy-myob_subscribe',
  BUTTON_LOGIN: 'button_buy-myob_login',
  BUTTON_EXISTING_USER_CHECK: 'button_buy-myob_existing_user_check',
  BUTTON_CREATE_NEW_TRIAL: 'button_buy-myob_create-new-trial',
  BUTTON_RESUME_TRIAL: 'button_buy-myob_resume-trial',
  BUTTON_ACCESS_SOFTWARE: 'button_buy-myob_access-software',
  LINK_TERMS_OF_USE: 'link_buy-myob_terms-of-use',
  LINK_PRIVACY: 'link_buy-myob_privacy',
  INPUT_EMAIL: 'input_buy-myob_email',
  INPUT_FIRST_NAME: 'input_buy-myob_first-name',
  INPUT_LAST_NAME: 'input_buy-myob_last-name',
  INPUT_PHONE_NUMBER: 'input_buy-myob_phone-number',
  INPUT_BUSINESS_NAME: 'input_buy-myob_business-name'
};

export const SHOW_MODULE_PRICE_PRODUCT_ID = [
  '76', '77', '78', '79'
];

export const PAYROLL_MODULE_LIMIT = {
  74: 'unlimited',
  76: 'up to 2',
  77: 'up to 2',
  78: 'unlimited',
  79: 'unlimited'
};

export const PAYROLL_PRICE_PLACEHOLDER = 'payrollPricePlaceHolder';
export const COOKIE_AJS_ANONYMOUS_ID = 'ajs_anonymous_id';
export const COOKIE_MYOB_VISITOR_ID = 'myob_visitor_id';
export const COOKIE_AJS_USER_ID = 'ajs_user_id';

export const PAYMENT_TERMS_ERROR_MESSAGE = "Please confirm you've acknowledged the terms & conditions";

export const PPL_PRODUCT_IDS = ['75', '77', '79'];
export const NZ_PPL_PRODUCT_IDS = ['70', '72', '74', '76', '78'];
export const NZ_PAYROLL_ONLY_PRODUCT_ID = '74';
export const PRODUCT_MAPPING = {
  74: '80',
  76: '80',
  77: '81',
  78: '80',
  79: '81'
};

const WESTPAC_REFERRAL_FIRST_6_DIGITS = [
  429813,
  516315,
  429813,
  516315,
  516349,
  516369,
  516328,
  516366,
  429317,
  471572,
  516315,
  516325,
  516335,
  516345,
  516355,
  516365,
  516375,
  516385,
  555003
];
export const WESTPAC_DIGITS_RELATION = {
  [PROMOTION_CODE.CODE_WESTPACBUSINESSACC_12MTH_FREE]: WESTPAC_REFERRAL_FIRST_6_DIGITS
};

export const STEPPER_TYPE = {
  TRIAL: 'TRIAL',
  PURCHASE: 'PURCHASE'
};

export const ANNUAL_BASE_DISCOUNT = 0.75;

export const FEATURE_TOGGLE = {
  PROMO_BOX_FEATURE_TOGGLE: 'promo_box_toggle'
};

export const CHECKOUT_VISITOR_ID = 'checkout_visitor_id';
