import React, { useState } from 'react';
import { Spinner } from '@myob/myob-widgets';
import { Redirect } from 'react-router-dom';
import './OrderSummary.scss';
import { ProductPrices } from '../productPrices/ProductPrices';
import { TickList } from '../tickList/TickList';
import { getTerms } from '../terms/Terms';
import chevronDown from '../../assets/svg/chevron_down.svg';
import { useProductData } from '../../hooks/useProductData/useProductData';

export const OrderSummary: React.FC = () => {
  const {
    isLoading, isModuleProductDataLoading,
    isError, isGetModuleProductDataError,
    productData, moduleProductData,
    baseFeatureList, baseAndModuleFeatureList,
    productId, showModulePrice,
    promoCode, availableTo
  } = useProductData();

  const [featureClassName, setFeatureClassName] = useState('ul-order-summary-hide');

  if (isError || isGetModuleProductDataError) {
    return <Redirect to='/error' />;
  }

  const getProductFeature = () => (
    <div className={featureClassName} style={{ fontSize: '20px' }}>
      <div className='feature-list-container' data-testid='tick-list'>
        <TickList
          styles={{ color: 'black', display: 'inline-block' }}
          items={showModulePrice(productId) ? baseAndModuleFeatureList : baseFeatureList}
        />
      </div>
    </div>
  );

  const onExpandButtonClick = () => {
    if (featureClassName === 'ul-order-summary-hide') {
      setFeatureClassName('ul-order-summary-show');
    } else {
      setFeatureClassName('ul-order-summary-hide');
    }
  };

  const getExpandButton = () => (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
    }}
    >
      <div style={{ margin: '0' }}>
        <button className= 'button-expand'
          onClick={onExpandButtonClick}
        >
          <div className='button-expand-name-container'>
            <img
              className={
                featureClassName === 'ul-order-summary-hide' ? 'chevronDown' : 'rotate chevronDown'
              }
              src={chevronDown}
              alt={'⌄'}
            />
            {featureClassName === 'ul-order-summary-hide' ? 'EXPAND' : 'COLLAPSE'}
          </div>
        </button>
      </div>
    </div>
  );

  return (
    <>
      <div className='orderSummary-container' data-testid='order-summary' style={{ width: '100%', padding: '0 3rem' }}>
        <h1 className='title-container'>Summary of your plan</h1>
        {isLoading || isModuleProductDataLoading ? <Spinner size='medium' data-testid='spinner'/> :
          (<div>
            <div className='summary-of-your-plan-main'>
              <ProductPrices
                productData={productData}
                moduleProductData={moduleProductData}
              />
              {getProductFeature()}
              {getExpandButton()}
              {getTerms('terms-body', promoCode, availableTo)}
            </div>
          </div>)
        }
      </div>
    </>
  );
};
