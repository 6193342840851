import React from 'react';
import {
  Box, Separator, Spinner
} from '@myob/myob-widgets';
import './ProductCard.scss';
import { get } from 'lodash';
import { Redirect } from 'react-router-dom';
import { PricingBox } from './PricingBox';
import { FeatureList } from './FeatureList';
import { ProductTitle } from './ProductTitle';

import { getTerms } from '../terms/Terms';
import { useProductData } from '../../hooks/useProductData/useProductData';

const ProductCard: React.FC = () => {
  const {
    isLoading, isModuleProductDataLoading,
    isError, isGetModuleProductDataError,
    productData, moduleProductData,
    baseFeatureList, baseAndModuleFeatureList,
    productId, showModulePrice,
    promoCode, availableTo
  } = useProductData();

  const items = get(productData, 'items');

  if (isError || isGetModuleProductDataError) {
    return <Redirect to='/error' />;
  }

  return (
    <Box
      className='left-hand-side'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        className='product-card'
        data-testid='product-box'
        borderColor='borderDefault'
        borderWidth='thin'
        borderStyle='solid'
        borderRadius='xl'
        boxShadow='strong'
        padding='lg'
      >
        {isLoading || isModuleProductDataLoading ?
          <Spinner size='medium' data-testid='spinner' /> :
          (<div>
            <ProductTitle productName={items[0]?.displayName} />
            <Separator className='title-separator' data-testid='title-separator' />
            <PricingBox productData={productData} moduleProductData={moduleProductData}/>
            <FeatureList items={showModulePrice(productId) ? baseAndModuleFeatureList : baseFeatureList} />
            {getTerms('terms-and-conditions', promoCode, availableTo)}
          </div>)}
      </Box>
    </Box>
  );
};

export default ProductCard;
