import axios from 'helpers/axios/axios';
import qs from 'qs';
import config from 'Config';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { useSetDuplicateTrial } from 'hooks/useDuplicateTrialCheck/useDuplicateTrialCheck';
import { useFormSubmit, useFormCompleted } from 'hooks/Analytics/useAnalytics';
import { useRecaptcha } from 'hooks/useRecaptcha/useRecaptcha';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import {
  TRANSACTION_TYPE,
  SOURCE_INITIATIVE,
  REQUEST_HEADERS,
  ANALYTICS_ID
} from '../../constants';
import { ClientInformation, CustomOrderData } from '../../helpers/orderDataHelper/types';
import { buildOrderData } from '../../helpers/orderDataHelper/buildOrderData';

type UseTrialOrderingCallType = {
  isLoading: boolean;
  isError: boolean;
  orderNumber: string;
  isSuccess: boolean;
}

export const useTrialOrderingCall = (): UseTrialOrderingCallType => {
  const {
    isLoading: isAuth0Loading,
    error: auth0Error,
    user,
    appState,
    getTokenSilently
  } = useAuth0();
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    (async (): Promise<void> => {
      if (!isAuth0Loading) {
        const accessToken = await getTokenSilently();
        setAccessToken(accessToken);
      }
    })();
  }, [getTokenSilently, isAuth0Loading]);

  const { region, productId, promoCode } = useSessionContext();
  const contactIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  // Use email from identity token, not from form payload
  const email = user?.email;
  const clientInformation = { ...get(appState, 'clientInformation'), email } as ClientInformation;

  const {
    productData,
    isLoading: isProductDataLoading,
    isError: isProductDataError
  } = useGetProduct();
  const trialDeal = get(get(productData, 'items[0]'), 'trialDeal');

  useFormSubmit({ elementId: ANALYTICS_ID.TRIAL_FORM });

  const {
    isLoading: isRecaptchaLoading,
    success: isRecaptchaSuccess
  } = useRecaptcha({
    enabled: true, action: 'submit', identityId: contactIdentityId, honeypotVar: clientInformation.website
  });

  const customOrderData = {
    transactionType: TRANSACTION_TYPE.TRIAL,
    sourceInitiative: SOURCE_INITIATIVE.WEBSITE,
    items: [{
      productId,
      deal: trialDeal
    }],
    region,
    optIn: true,
    promoCode
  } as CustomOrderData;
  const orderPayload = buildOrderData(customOrderData, clientInformation, undefined);
  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };
  const {
    isLoading: isOrderingLoading,
    isError: isOrderingError,
    isSuccess,
    data
  } = useQuery(
    'trialOrder',
    () => axios.post(
      `${config.API_CHECKOUT_ENDPOINT_AUTH0}`,
      qs.stringify(orderPayload),
      options
    ),
    { enabled: !!productData && trialDeal !== undefined && !!accessToken && !!isRecaptchaSuccess, retry: false }
  );

  const { setOrderNumber } = useSessionContext();
  useEffect(() => {
    if (isSuccess) {
      setOrderNumber(data?.data.orderNumber as string);
    }
  }, [isSuccess]);

  useFormCompleted({ elementId: ANALYTICS_ID.TRIAL_FORM, isError: isOrderingError });

  useSetDuplicateTrial(isSuccess, contactIdentityId);

  const isLoading =
    isAuth0Loading || isProductDataLoading || isOrderingLoading || isRecaptchaLoading;
  const isError =
    !!auth0Error ||
    isProductDataError ||
    isOrderingError ||
    isRecaptchaSuccess === false;
  const orderNumber = data?.data.orderNumber as string;
  return {
    isLoading, isError, orderNumber, isSuccess
  };
};
