import React, { memo } from 'react';
import { Checkbox } from '@myob/myob-widgets';
import './TermsOfUse.scss';
import { PPL_PRODUCT_IDS } from '../../constants';
import isNzPPLProduct from '../../utils/IsNzPPLProduct';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

type TermsOfUseProps = {
  isTermsCheck: boolean;
  termsErrorMessage: string;
  setTerms: () => void;
  sendPromotionsCheck:boolean;
  setSendPromotions: () => void;
  productId: string
}

const getTermsOfUseUrl = (productId: string): string => {
  if (isNzPPLProduct(productId)) {
    return 'https://www.myob.com/nz/support/customer-service/myob-legal-notices/myob-business-terms-of-use';
  }
  const termsOfUseLinkSuffix =
      PPL_PRODUCT_IDS.includes(productId) ? 'myob-business-terms-of-use' : 'EssentialsTermsofUse';
  return `https://www.myob.com/au/support/customer-service/myob-legal-notices/${termsOfUseLinkSuffix}`;
};

const getPrivacyPolicyUrl = (productId: string): string => (isNzPPLProduct(productId) ?
  'https://www.myob.com/nz/privacy-policy' :
  'https://www.myob.com/au/privacy-policy');

const termsOfUseElement = (productId: string) =>
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={getTermsOfUseUrl(productId)}
  >
    {' '}Terms of Use
  </a>;

const offerTermsAndConditionsElement = (href: string) =>
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={href}
  >
    {' '}Offer Terms and Conditions{' '}
  </a>;

const privacyPolicyElement = (productId: string) =>
  <a
    target='_blank'
    rel='noopener noreferrer'
    href={getPrivacyPolicyUrl(productId)}
  >
    {' '}Privacy Policy.
  </a>;

const getTermsAndConditions = (productId: string, promoCode: string, promotionTermsLink: string) => {
  const getTCWithPromotion = () => {
    const getTCWithPromotionElement = (promotionTermsLink: string) =>
      <div className={'terms-of-use'}>
        <span data-testid={'t&c'}>
          I&apos;ve read and agreed to the
          {termsOfUseElement(productId)}
          , the {offerTermsAndConditionsElement(promotionTermsLink)} and our
          {privacyPolicyElement(productId)}
        </span>
      </div>;
    if (promoCode && promotionTermsLink) {
      return getTCWithPromotionElement(promotionTermsLink);
    }
    return getTCWithoutPromotionElement();
  };

  const getTCWithoutPromotionElement = () =>
    <div className={'terms-of-use'}>
      <span data-testid={'t&c'}>
        I&apos;ve read and agreed to the
        {termsOfUseElement(productId)}
        {' '}and
        {privacyPolicyElement(productId)}
      </span>
    </div>;

  return getTCWithPromotion();
};

const TermsOfUse: React.FC<TermsOfUseProps> = ({
  isTermsCheck, termsErrorMessage, setTerms, sendPromotionsCheck, setSendPromotions,
  productId
}) => {
  const { promoCode, promotionTermsLink } = useSessionContext();
  return (
    <div>
      <Checkbox
        className={'terms-of-use-checkbox'}
        data-testid='terms-of-use'
        name='terms-of-use'
        label={getTermsAndConditions(productId, promoCode, promotionTermsLink)}
        onChange={() => setTerms()}
        checked={isTermsCheck}
        errorMessage={termsErrorMessage}
      />

      <Checkbox
        className={'send-promotion-checkbox'}
        data-testid='send-promotion'
        name='send-promotion'
        label={<div className={'do-not-send-promotion'}>
          <span>
            Keep me in the loop with the latest news and special offers.
          </span>
        </div>}
        onChange={() => setSendPromotions()}
        checked={sendPromotionsCheck}
      />
    </div>
  );
};
export default memo(TermsOfUse);
