import { get } from 'lodash';
import { useQuery } from 'react-query';
import qs from 'qs';
import { MakePurchaseType, PurchaseResponse, PurchaseRequest } from './types';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { useAuth0 } from '../Auth0/Auth0';
import { useGetAccessToken } from '../useGetAcessToken/useGetAccessToken';
import { useGetProduct } from '../useGetProduct/useGetProduct';
import {
  REQUEST_HEADERS, SOURCE_INITIATIVE, SUBSCRIPTION_TYPE, TRANSACTION_TYPE
} from '../../constants';
import axios from '../../helpers/axios/axios';
import config from '../../Config';

const errorResponse: MakePurchaseType = {
  data: undefined,
  isError: true,
  isLoading: false,
  isSuccess: false
};

export default function useAnnualSubscriptionPurchase(purchaseRequest: PurchaseRequest): MakePurchaseType {
  /* eslint-disable react-hooks/rules-of-hooks */
  const {
    accountId, isRecaptchaSuccess, paymentProfileId, contactId
  } = purchaseRequest;
  const { productId, subscriptionType, hasPurchaseSubmitted } = useSessionContext();
  if (subscriptionType !== SUBSCRIPTION_TYPE.SUBSCRIBE) {
    return errorResponse;
  }
  const { appState } = useAuth0();
  const optIn = get(appState, 'clientInformation.sendPromotions');
  const { accessToken } = useGetAccessToken();
  const { productData } = useGetProduct();
  const dealId = get(productData, 'items[0].deal.id');

  const purchasePayload = {
    sourceInitiative: SOURCE_INITIATIVE.WEBSITE,
    accountId,
    contactId,
    optIn,
    paymentProfileId,
    productId,
    dealId,
    quality: 1,
    termsAndConditions: true,
    transactionType: TRANSACTION_TYPE.DIRECT_PURCHASE
  };
  const options = {
    headers: {
      ...REQUEST_HEADERS,
      Authorization: `Bearer ${accessToken}`
    }
  };

  const enabled = !!productData &&
    !!accessToken &&
    !hasPurchaseSubmitted &&
    !!isRecaptchaSuccess &&
    !!paymentProfileId;

  return useQuery(
    'purchase',
    () => axios.post<PurchaseResponse>(
      config.API_ANNUAL_PURCHASE_ENDPOINT,
      qs.stringify(purchasePayload),
      options
    ),
    { enabled, retry: false }
  );
  /* eslint-enable react-hooks/rules-of-hooks */
}
