import React from 'react';
import './Terms.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import useGetTermsConditions from '../../hooks/useGetTermsConditions/useGetTermsConditions';

export const getTerms = (
  termsClassName: string,
  promoCode: string,
  promotionEndDate: string | undefined
): React.ReactElement | undefined => {
  if (promoCode && promotionEndDate) {
    return (
      <div className={termsClassName}>
        {
          <TermsConditions />
        }
      </div>
    );
  }
  return undefined;
};

const TermsConditions: React.FC = () => {
  const { termsConditions, isLoading, isError } = useGetTermsConditions();
  return (
    isLoading || isError || termsConditions === undefined ? null : <div data-testid={'terms-conditions-test'}>
      {documentToReactComponents(termsConditions, {
        renderNode: {
          [INLINES.HYPERLINK]: (node, children) =>
            <a href={node.data.uri} target='_blank' rel='noreferrer'>{children}</a>
        }
      })}
    </div>
  );
};
