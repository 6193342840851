import { useAuth0 } from 'hooks/Auth0/Auth0';

import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { get } from 'lodash';
import { useEffect } from 'react';
import { ANALYTICS_ID } from '../../constants';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { useFormCompleted, useFormSubmit } from '../Analytics/useAnalytics';
import { useRecaptcha } from '../useRecaptcha/useRecaptcha';
import { UsePurchaseType } from './types';
import useSubscriptionPurchase from './useSubscriptionPurchase';
import useAnnualSubscriptionPurchase from './useAnnualSubscriptionPurchase';

export const usePurchase = ({
  accountId, pCustomerId, paymentProfileId, contactId
}: { accountId: string, pCustomerId: string, paymentProfileId?: string, contactId?: string}): UsePurchaseType => {
  const {
    isLoading: isAuth0Loading, user, appState, error: isAuth0Error
  } = useAuth0();

  const { isLoading: isProductDataLoading, isError: isProductDataError } = useGetProduct();

  const honeypotVar = get(appState, 'clientInformation.website');
  const contactIdentityId = user?.['https://id.myob.com/user_id'].toLowerCase();
  useFormSubmit({ elementId: ANALYTICS_ID.SUBSCRIBE_FORM });

  const { setHasPurchaseSubmitted, isAnnualPrice } = useSessionContext();
  const {
    isLoading: isRecaptchaLoading,
    success: isRecaptchaSuccess
  } = useRecaptcha({
    enabled: true, action: 'submit', identityId: contactIdentityId, honeypotVar
  });

  const usePurchaseHandler = isAnnualPrice ? useAnnualSubscriptionPurchase : useSubscriptionPurchase;
  const {
    isLoading: isPurchasingLoading,
    isError: isPurchasingError,
    isSuccess,
    data
  } = usePurchaseHandler({
    accountId, pCustomerId, isRecaptchaSuccess: !!isRecaptchaSuccess, paymentProfileId, contactId
  });

  useFormCompleted({ elementId: ANALYTICS_ID.SUBSCRIBE_FORM, isError: isPurchasingError });
  useEffect(() => {
    if (isSuccess || isPurchasingError) {
      setHasPurchaseSubmitted(true);
    }
  }, [isSuccess, isPurchasingError]);

  const { setSerialNumber, setProductInstanceId, setOrderNumber } = useSessionContext();
  useEffect(() => {
    if (isSuccess) {
      setSerialNumber(data?.data.serialNumber as string);
      setProductInstanceId(data?.data.productInstanceId as string);
      setOrderNumber((data?.data.purchaseId || data?.data.orderNumber) as string);
    }
  }, [isSuccess]);

  const isLoading = isAuth0Loading || isProductDataLoading || isPurchasingLoading || isRecaptchaLoading;
  const isError = isProductDataError || isPurchasingError || !!isAuth0Error || isRecaptchaSuccess === false;
  const orderNumber = (data?.data.purchaseId || data?.data.orderNumber) as string;
  return {
    isError, isLoading, orderNumber, isSuccess
  };
};
