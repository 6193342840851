import { get } from 'lodash';
import { useGetProduct } from '../useGetProduct/useGetProduct';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';
import { useGetModuleProduct } from '../useGetModuleProduct/useGetModuleProduct';
import isNzPPLProduct from '../../utils/IsNzPPLProduct';
import {
  getProductFeatureList,
  getProductFeatureListWithPayrollPrice
} from '../../components/FeatureList/ProductFeatureMapping';
import { SHOW_MODULE_PRICE_PRODUCT_ID } from '../../constants';

type UseProductData = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  isModuleProductDataLoading: boolean;
  isGetModuleProductDataError: boolean;
  isGetModuleProductDataSuccess: boolean;
  productData: any; // Replace 'any' with the actual type of productData
  moduleProductData: any; // Replace 'any' with the actual type of moduleProductData
  baseFeatureList: string[];
  baseAndModuleFeatureList: string[];
  productId: string;
  showModulePrice: (productId: string) => boolean;
  promoCode: string;
  availableTo: string | undefined;
};

export const useProductData = (): UseProductData => {
  const showModulePrice = (productId: string): boolean => SHOW_MODULE_PRICE_PRODUCT_ID.includes(productId);
  const getValueWithTwoDecimal = (value: number) : string => value.toFixed(2);

  const {
    isLoading, productData, isError, isSuccess
  } = useGetProduct();

  const {
    productId, promoCode, setProductName, setProductFreePeriod, setHasPromoCode,
    setPromotionEndDate, setPromotionTermsLink
  } = useSessionContext();

  const items = get(productData, 'items');
  const availableTo = items ? items[0]?.deal?.availableTo : '';
  const promotionTerms = items ? items[0]?.deal?.promotionTerms : '';
  const hasPromotionCode = (items !== undefined && items[0] !== undefined) ? !!items[0].deal.promotionName : false;
  setHasPromoCode(hasPromotionCode);

  if (isSuccess) {
    setProductName(items[0].displayName);
    setProductFreePeriod(items[0].deal.freePeriod);
    availableTo && setPromotionEndDate(availableTo);
    promotionTerms && setPromotionTermsLink(promotionTerms);
  }

  const {
    isLoading: isModuleProductDataLoading, productData: moduleProductData,
    isError: isGetModuleProductDataError, isSuccess: isGetModuleProductDataSuccess
  } = useGetModuleProduct(isSuccess, items);

  const baseFeatureList = getProductFeatureList(productId);
  let baseAndModuleFeatureList: string[] = [];
  if (isGetModuleProductDataSuccess && showModulePrice(productId)) {
    const originalPayrollPrice = isNzPPLProduct(productId) ?
      moduleProductData.items[0].priceWithoutTax : moduleProductData.items[0].totalPrice;
    const payrollPrice = getValueWithTwoDecimal(originalPayrollPrice);
    baseAndModuleFeatureList = getProductFeatureListWithPayrollPrice(baseFeatureList, payrollPrice);
  }

  return {
    isLoading,
    isError,
    isSuccess,
    isModuleProductDataLoading,
    isGetModuleProductDataError,
    isGetModuleProductDataSuccess,
    productData,
    moduleProductData,
    baseFeatureList,
    baseAndModuleFeatureList,
    productId,
    showModulePrice,
    promoCode,
    availableTo
  };
};
